.FixedMenu {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 100;
  /* background-color: #6cb241 !important; */
}
.topNav {
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #6cb241;
  color: white;
}
.topNav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.topNav ul li {
  padding-right: 30px;
}
.topNav svg {
  margin-left: 20px;
}
/* ----Menu------------------------------------------------------------------------------------------ */
.menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  /* background-color: green; */
  width: 60%;
  margin-left: 40%;
  text-align: right;
  justify-content: space-between;
}
.menu {
  padding-top: 10px;
  padding-bottom: 10px;
}
.logopart {
  height: 80px;
}

.menu ul li {
  /* flex: 1; */
  text-align: right;
}
.Collape {
  display: none;
}
.menulist_pack {
  padding-top: 30px;
}

@media only screen and (orientation: portrait) {
  .xxxx {
    display: none;
  }
  .topNav {
    height: 35px;
    /* display: none; */
  }
  .logopart {
    height: 50px;
  }
  .menulist_pack {
    display: none;
  }
  .Collape {
    display: block;
    text-align: right;
    /* margin-top: 4vh; */
  }
  .FixedMenu {
    height: 100px;
  }
}
