@import url("https://fonts.googleapis.com/css?family=Josefin+Slab&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
}

.process {
  width: 100%;
  padding: 0 15px;
  text-align: center;
}
.process__item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  position: relative;
  padding: 15px 35px;
  transition: 0.4s ease-in-out;
}
.process__item:hover {
  background: #f2f2f2;
}
.process__item:hover .process__number {
  transform: translateY(5px);
  color: #0772ba;
}
.process__number {
  font-size: 90px;
  -webkit-text-stroke: 1px #0772ba;
  display: block;
  color: transparent;

  font-weight: 700;
  transition: 0.4s ease-in-out;
}
.process__title {
  display: block;

  font-weight: 700;
  letter-spacing: 1.5px;
  font-size: 35px;
  color: #0772ba;
  text-transform: uppercase;
  margin-top: 30px;
}
.process__subtitle {
  display: block;
  margin-top: 30px;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .process {
    display: inline-block;
  }
  .process__item {
    width: 49%;
    display: inline-block;
  }
}
@media (min-width: 1200px) {
  .process {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .process__item {
    width: 100%;
  }
  .process__item:not(:last-of-type)::after {
    content: "";
    width: 1px;
    height: 75%;
    background: #8c8c8c;
    position: absolute;
    right: 0;
    top: 50%;
    opacity: 0.2;
    transform: translateY(-50%);
  }
}


.section-services {
	padding-top: 110px;
	padding-bottom: 120px;
 
	background-color: #211f24;
	color: #fff;
}

.section-services .header-section {
	margin-bottom: 35px;
}

.section-services .header-section .title {
	position: relative;
	margin-bottom: 40px;
	padding-bottom: 25px;
	text-transform: uppercase;
	font-weight: 700;
}

.section-services .header-section .title:before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 140px;
	height: 1px;
	background-color: #0772ba;
}

.section-services .header-section .title:after {
	content: "";
	position: absolute;
	bottom: -1px;
	left: 50%;
	transform: translateX(-50%);
	width: 45px;
	height: 3px;
	background-color: #0772ba;
}

.section-services .header-section .title span {
	color: #0772ba;
}

.section-services .header-section .description {
	color: #6f6f71;
}

.section-services .single-service {
	margin-top: 40px;
	background-color: #24252a;
	box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
}

.section-services .single-service .part-1 {
	padding: 40px 40px 25px;
	border-bottom: 2px solid #1d1e23;
}

.section-services .single-service .part-1 i {
	margin-bottom: 25px;
	font-size: 50px;
	color: #0772ba;
}

.section-services .single-service .part-1 .title {
	font-size: 17px;
	font-weight: 700;
	letter-spacing: 0.02em;
	line-height: 1.8em;
}

.section-services .single-service .part-2 {
	padding: 30px 40px 40px;
}

.section-services .single-service .part-2 .description {
	margin-bottom: 22px;
	color: #6f6f71;
	font-size: 14px;
	line-height: 1.8em;
}

.section-services .single-service .part-2 a {
	color: #fff;
	font-size: 14px;
	text-decoration: none;
}

.section-services .single-service .part-2 a i {
	margin-right: 10px;
	color: #0772ba;
}