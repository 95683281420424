.bx {
  height: 180px;
  /* background-color: white; */
  z-index: 1000;
  margin: 0;
}
.bx img {
  width: 100%;
  height: 180px;
}
.bx h4 {
  position: relative;
  margin-top: -100px;
  background-color: white;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  color: white;
  font-weight: bold;
}
.about {
  position: relative;
  z-index: 1000;
}
.areaX {
  background: linear-gradient(90deg, #effcf3 50%, #edf3f5 50%);
  z-index: -1;
}

@media only screen and (orientation: portrait) {
  .bx {
    height: auto;
    /* background-color: white; */
    z-index: 1000;
    margin: 0;
  }
  .bx img {
    width: 100%;
    height: auto;
  }
  .bx0 {
    display: none;
  }
}
