.section_all {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  min-height: 100vh;
  padding-top: 20vh;
  z-index: -1;
}
.section-title {
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.section-subtitle {
  letter-spacing: 0.4px;
  line-height: 28px;
  max-width: 550px;
}

.section-title-border {
  background-color: #000;
  height: 1 3px;
  width: 44px;
}

.section-title-border-white {
  background-color: #fff;
  height: 2px;
  width: 100px;
}
.text_custom {
  /* color: #00bd2a; */
  color: #0772ba;
}

.about_icon i {
  font-size: 22px;
  height: 65px;
  width: 65px;
  line-height: 65px;
  display: inline-block;
  background: #fff;
  border-radius: 35px;
  color: #00bd2a;
  color: #0772ba;

  box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
}

.about_header_main .about_heading {
  max-width: 450px;
  font-size: 24px;
}

.about_icon span {
  position: relative;
  top: -10px;
}

.about_content_box_all {
  padding: 28px;
}

@media only screen and (orientation: portrait) {
  .section_all {
    padding-top: 5vh;
    z-index: -1;
  }
  .about_header_main {
    text-align: center;
  }
}
