.service {
  padding-bottom: 10vh;
}
.serviceTab {
  margin-top: 10vh;
}
.heading {
}
.heading span {
  font-weight: bold;
  font-size: 18px;
}
.heading label {
  height: 3px;
  width: 100px;
  background-color: red;
  margin: 4px;
  /* margin-right: 30px; */
}
.services {
  text-align: center;
  background-color: #cffadd;
  width: 90%;
  margin: auto;
  padding: 15px;
  margin-bottom: 10px;
}
.services h5 {
  font-weight: bold;
}
.services p {
  font-weight: 100;
  font-size: 13px;
  margin-top: 15px;
  height: 150px;
}
.services ion-icon {
  font-size: 100px;
  margin: 15px;
  margin-top: 10px;
  color: #be8f2d;
}
.serviceBtn ion-icon {
  font-size: 40px;
  background-color: aliceblue;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (orientation: portrait) {
  .service {
    padding-top: 4vh;
  }
  .heading label {
    height: 3px;
    width: 60px;
    background-color: red;
    margin: 4px;
    /* margin-right: 30px; */
  }
}
