#sponsorSection {
  text-align: center;
}

#sponsorSection h2 {
}

.sponsorLogo img {
  display: flex;
  margin: auto;
}

#sponsorSection p {
}

.sponsorLogo {
}
